import React from "react";
import { useParams, Navigate } from "react-router-dom";
import CarouselCustomer from "../components/Carousel";
import WebButton from "../components/webButton";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import customers from "../assets/data/customer.json";

export default function Customer() {
  const { t } = useTranslation();
  const { id } = useParams();
  const customer = customers.find((customer) => customer.id === id);

  if (!customer) {
    return <Navigate to="/" replace />;
  }

  // Function to render technology
  const renderTechnology = (technology) => {
    return technology.map((tech, index) => (
      <p className="mt-2" key={index}>
        - {tech}
      </p>
    ));
  };

  const scaleVariants = {
    hidden: { scale: 0.8, y: 30, opacity: 0 },
    visible: {
      scale: 1,
      y: 0,
      opacity: 1,
      transition: { duration: 0.8, delay: 0.8 },
    },
  };

  return (
    <div className="flex flex-col items-center mt-36 bg-[#0B0B0F] text-center xl:text-left">
      <div className="flex flex-wrap w-full xl:w-6/12 mb-10" key={id}>
        <motion.div
          className="mx-5 text-white"
          variants={scaleVariants}
          initial="hidden"
          animate="visible"
        >
          <h1 className="text-5xl font-title">{t(`customer.${id}.name`)}</h1>
          <p className="mt-10 xl:mr-52 xl:mx-0 lg:mx-32 md:mx-14 sm:mx-10">
            {t(`customer.${id}.description`)}
          </p>

          <div className="flex justify-between mt-20">
            <div className="w-6/12 pr-4">
              <h3 className="font-title text-2xl mb-5">{t('customer.tech')}</h3>
              {renderTechnology(customer.technology)}
            </div>

            <div className="w-6/12 pl-4">
              <h3 className="font-title text-2xl mb-5">{t('customer.time')}</h3>
              <p className="mb-10">{t(`customer.${id}.date`)}</p>
            </div>
          </div>
        </motion.div>
      </div>

      <div className="w-full xl:w-6/12">
        <CarouselCustomer pictures={customer.pictures} />
        <div className="text-center xl:mb-0 mb-12 xl:text-2xl text-xl">
          <a
            className="text-white"
            href={customer.link}
            target="_blank"
            rel="noreferrer"
          >
            <WebButton />
          </a>
        </div>
      </div>
    </div>
  );
}
