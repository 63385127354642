import React, { useState, useEffect } from "react";
import { FiChevronDown } from "react-icons/fi";
import { MdLaunch } from "react-icons/md";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";

export default function Experience() {
  const { t } = useTranslation();
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1000) {
        setIsInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const titleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const borderVariants = {
    hidden: { width: 0 },
    visible: {
      width: "15rem",
      transition: { duration: 0.5 },
    },
  };

  const slideUpVariants = (delay) => ({
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: delay },
    },
  });

  return (
    <section className="px-10 pt-8 pb-20 bg-[#0B0B0F]" id="experience">
      <div className="max-w-5xl mx-auto">
        <motion.h1
          className="text-2xl text-white font-bold pb-4 mb-1 w-60 uppercase"
          variants={titleVariants}
          initial="hidden"
          animate={controls}
        >
          {t('experience.title')}
        </motion.h1>
        <motion.div
          className="border-b-2 w-60 mb-10"
          variants={borderVariants}
          initial="hidden"
          animate={controls}
        ></motion.div>

        {/* PolarFi */}
        <motion.div
          variants={slideUpVariants(0.3)}
          initial="hidden"
          animate={controls}
        >
          <Question title={t('experience.polarFi.title')} description={t('experience.polarFi.description')}>
            <p className="text-primary-content">
              {t('experience.polarFi.content')}
            </p>
            <a
              className="flex items-center text-copy-light hover:text-copy-lighter duration-300 ease-in-out mt-3"
              href="https://polarfi.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('experience.polarFi.link')} <MdLaunch className="ml-2" />
            </a>
          </Question>
        </motion.div>

        {/* Kasa */}
        <motion.div
          variants={slideUpVariants(0.5)}
          initial="hidden"
          animate={controls}
        >
          <Question title={t('experience.kasa.title')} description={t('experience.kasa.description')}>
            <p className="text-primary-content">
              {t('experience.kasa.content')}
            </p>
            <a
              className="flex items-center text-copy-light hover:text-copy-lighter duration-300 ease-in-out mt-3"
              href="https://kasa.loganben.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('experience.kasa.link')} <MdLaunch className="ml-2" />
            </a>
          </Question>
        </motion.div>

        {/* Booki */}
        <motion.div
          variants={slideUpVariants(0.6)}
          initial="hidden"
          animate={controls}
        >
          <Question title={t('experience.booki.title')} description={t('experience.booki.description')}>
            <p className="text-primary-content">
              {t('experience.booki.content')}
            </p>
            <a
              className="flex items-center text-copy-light hover:text-copy-lighter duration-300 ease-in-out mt-3"
              href="https://booki.loganben.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('experience.booki.link')} <MdLaunch className="ml-2" />
            </a>
          </Question>
        </motion.div>

        {/* OhMyFood */}
        <motion.div
          variants={slideUpVariants(0.8)}
          initial="hidden"
          animate={controls}
        >
          <Question title={t('experience.ohMyFood.title')} description={t('experience.ohMyFood.description')}>
            <p className="text-primary-content">
              {t('experience.ohMyFood.content')}
            </p>
            <a
              className="flex items-center text-copy-light hover:text-copy-lighter duration-300 ease-in-out mt-3"
              href="https://ohmyfood.loganben.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('experience.ohMyFood.link')} <MdLaunch className="ml-2" />
            </a>
          </Question>
        </motion.div>

        {/* Kanap */}
        <motion.div
          variants={slideUpVariants(1)}
          initial="hidden"
          animate={controls}
        >
          <Question title={t('experience.kanap.title')} description={t('experience.kanap.description')}>
            <p className="text-primary-content">
              {t('experience.kanap.content')}
            </p>
            <a
              className="flex items-center text-copy-light hover:text-copy-lighter duration-300 ease-in-out mt-3"
              href="https://kanap.loganben.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('experience.kanap.link')} <MdLaunch className="ml-2" />
            </a>
          </Question>
        </motion.div>
      </div>
    </section>
  );
}

const Question = ({ title, children, defaultOpen = false, description }) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <motion.div
      animate={open ? "open" : "closed"}
      className="border-b-[1px] border-b-primary-content"
    >
      <button
        onClick={() => setOpen((pv) => !pv)}
        className="py-6 w-full flex items-center justify-between gap-4"
      >
        <div className="flex flex-col">
          <motion.span
            variants={{
              open: {
                color: "#d9d9d9",
              },
              closed: {
                color: "#a6a6a6",
              },
            }}
            className="text-lg font-medium text-left bg-gradient-to-r from-copy-light to-copy-lighter bg-clip-text"
          >
            {title}
          </motion.span>
          <motion.p
            variants={{
              open: {
                color: "#d9d9d9",
              },
              closed: {
                color: "#a6a6a6",
              },
            }}
            className="text-sm font-medium text-left bg-gradient-to-r from-copy-light to-copy-lighter bg-clip-text"
          >
            {description}
          </motion.p>
        </div>
        <motion.span
          variants={{
            open: {
              rotate: "180deg",
              color: "#d9d9d9",
            },
            closed: {
              rotate: "0deg",
              color: "#404040",
            },
          }}
        >
          <FiChevronDown className="text-2xl" />
        </motion.span>
      </button>
      <motion.div
        initial={false}
        animate={{
          height: open ? "fit-content" : "0px",
          marginBottom: open ? "24px" : "0px",
        }}
        className="overflow-hidden text-slate-600"
      >
        {children}
      </motion.div>
    </motion.div>
  );
};
