import { motion, useAnimation } from "framer-motion";
import { FiCheckCircle } from "react-icons/fi";
import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";

export default function Contact() {
  const { t } = useTranslation();
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1600) {
        setIsInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

    const titleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const borderVariants = {
    hidden: { width: 0 },
    visible: {
      width: "15rem",
      transition: { duration: 0.5 },
    },
  };

  const slideUpVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 0.5 },
    },
  };

  const containerRef = useRef(null);
  const inputRef = useRef(null);

  return (
    <section className="px-4 py-12 bg-[#0B0B0F]" id="contact">
      <div className="max-w-5xl mx-auto">
              <motion.h1
                    className="text-2xl text-white font-bold pb-4 mb-1 w-60 uppercase"
                    variants={titleVariants}
                    initial="hidden"
                    animate={controls}
                  
        >
          {t('contact.title')}
        </motion.h1>
        <motion.div
          className="border-b-2 w-60 mb-10"
          variants={borderVariants}
          initial="hidden"
          animate={controls}
        ></motion.div>
      <motion.div
        variants={slideUpVariants}
        initial="hidden"
        animate={controls}
        ref={containerRef}
        onClick={() => {
          inputRef.current?.focus();
        }}
        className="h-96 bg-[#1f1f1f] backdrop-blur rounded-lg w-full max-w-3xl mx-auto overflow-y-scroll shadow-xl cursor-text font-mono"
      >
        <TerminalHeader />
        <TerminalBody inputRef={inputRef} containerRef={containerRef} />
      </motion.div>
      </div>
    </section>
  );
}

const TerminalHeader = () => {
  return (
    <div className="w-full p-3 bg-slate-900 flex items-center gap-1 sticky top-0">
      <div className="w-3 h-3 rounded-full bg-red-500" />
      <div className="w-3 h-3 rounded-full bg-yellow-500" />
      <div className="w-3 h-3 rounded-full bg-green-500" />
      <span className="text-sm text-slate-200 font-semibold absolute left-[50%] -translate-x-[50%]">
        contact@loganben.com
      </span>
    </div>
  );
};

const TerminalBody = ({ containerRef, inputRef }) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const [text, setText] = useState("");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const QUESTIONS = [
      {
        key: "email",
        text: t('contact.questions.email.text'),
        postfix: t('contact.questions.email.postfix'),
        complete: false,
        value: "",
      },
      {
        key: "name",
        text: t('contact.questions.name.text'),
        postfix: t('contact.questions.name.postfix'),
        complete: false,
        value: "",
      },
      {
        key: "description",
        text: t('contact.questions.description.text'),
        postfix: t('contact.questions.description.postfix'),
        complete: false,
        value: "",
      },
    ];

    setQuestions(QUESTIONS);
  }, [t]); // Only depend on the translation function

  const curQuestion = questions.find((q) => !q.complete);

  const handleSubmitLine = (value) => {
    if (curQuestion) {
      if (curQuestion.key === "email" && !validateEmail(value)) {
        return;
      }

      setQuestions((pv) =>
        pv.map((q) => {
          if (q.key === curQuestion.key) {
            return {
              ...q,
              complete: true,
              value,
            };
          }
          return q;
        })
      );
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="p-2 text-slate-100 text-lg">
      <InitialText />
      <PreviousQuestions questions={questions} />
      <CurrentQuestion curQuestion={curQuestion} />
      {curQuestion ? (
        <CurLine
          text={text}
          focused={focused}
          setText={setText}
          setFocused={setFocused}
          inputRef={inputRef}
          command={curQuestion?.key || ""}
          handleSubmitLine={handleSubmitLine}
          containerRef={containerRef}
          curQuestion={curQuestion}
        />
      ) : (
        <Summary questions={questions} setQuestions={setQuestions} />
      )}
    </div>
  );
};



const InitialText = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('contact.titleContact')}
      <p className="whitespace-nowrap overflow-hidden font-light">
        ------------------------------------------------------------------------
      </p>
    </>
  );
};

const PreviousQuestions = ({ questions }) => {
  return (
    <>
      {questions.map((q, i) => {
        if (q.complete) {
          return (
            <Fragment key={i}>
              <p>
                {q.text || ""}
                {q.postfix && (
                  <span className="text-violet-300">{q.postfix}</span>
                )}
              </p>
              <p className="text-emerald-300">
                <FiCheckCircle className="inline-block mr-2" />
                <span>{q.value}</span>
              </p>
            </Fragment>
          );
        }
        return <Fragment key={i}></Fragment>;
      })}
    </>
  );
};

const CurrentQuestion = ({ curQuestion }) => {
  if (!curQuestion) return <></>;

  return (
    <p>
      {curQuestion.text || ""}
      {curQuestion.postfix && (
        <span className="text-violet-300">{curQuestion.postfix}</span>
      )}
    </p>
  );
};

const Summary = ({ questions, setQuestions }) => {
  const { t } = useTranslation();
  const [complete, setComplete] = useState(false);

  const handleReset = () => {
    setQuestions((pv) => pv.map((q) => ({ ...q, value: "", complete: false })));
  };

  const handleSend = () => {

    const formData = questions.reduce((acc, val) => {
      return { ...acc, [val.key]: val.value };
    }, {});
  
    // Use EmailJS to send the email
    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, {
      from_email: formData.email, 
      from_name: formData.name,  
      message: formData.description,
    }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
    .then((response) => {
      console.log('Email successfully sent!', response.status, response.text);
      setComplete(true);
    })
    .catch((error) => {
      console.error('Failed to send email:', error);
    });
  };

  return (
    <>
      <p>{t('contact.summary')}</p>
      {questions.map((q) => {
        return (
          <p key={q.key}>
            <span className="text-blue-300">{q.key}:</span> {q.value}
          </p>
        );
      })}
      <p>{t('contact.lookGood')}</p>
      {complete ? (
        <p className="text-emerald-300">
          <FiCheckCircle className="inline-block mr-2" />
          <span>{t('contact.sent')}</span>
        </p>
      ) : (
        <div className="flex gap-2 mt-2">
          <button
            onClick={handleReset}
            className="px-3 py-1 text-base hover:opacity-90 transition-opacity rounded bg-slate-100 text-black"
          >
            {t('contact.restart')}
          </button>
          <button
            onClick={handleSend}
            className="px-3 py-1 text-base hover:opacity-90 transition-opacity rounded bg-indigo-500 text-white"
          >
            {t('contact.sendIt')}
          </button>
        </div>
      )}
    </>
  );
};

const CurLine = ({
  text,
  focused,
  setText,
  setFocused,
  inputRef,
  command,
  handleSubmitLine,
  containerRef,
  curQuestion,
}) => {
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (curQuestion?.key === "email" && !validateEmail(text)) {
      setError(true);
      return;
    }

    setError(false);
    handleSubmitLine(text);
    setText("");
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  };

  const onChange = (e) => {
    setText(e.target.value);
    scrollToBottom();
  };

  useEffect(() => {
    return () => setFocused(false);
  }, [setFocused]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <label htmlFor="input-field" className="sr-only">
          {curQuestion?.text || t('contact.typeHere')}
        </label>
        <input
          id="input-field"
          ref={inputRef}
          onChange={onChange}
          value={text}
          type="text"
          className="sr-only"
          autoComplete="off"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          aria-label={curQuestion?.text || t('contact.typeHere')}
        />
      </form>
      <p>
        <span className="text-emerald-400">➜</span>
        <span className="text-cyan-300">~</span>
        {command && <span className="opacity-50">{t('contact.command')} {command}: </span>}
        {text}
        {focused && (
          <motion.span
            animate={{ opacity: [1, 1, 0, 0] }}
            transition={{
              repeat: Infinity,
              duration: 1,
              ease: "linear",
              times: [0, 0.5, 0.5, 1],
            }}
            className="inline-block w-2 h-5 bg-slate-400 translate-y-1 ml-0.5"
          />
        )}
      </p>
      {error && (
        <p className="text-red-500">{t('contact.invalidEmail')}</p>
      )}
    </>
  );
};



