import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import polarFi from "../assets/img/banner/polar.png";
import kasa from "../assets/img/banner/kasa2.png";
import booki from "../assets/img/banner/booki2.png";
import ohmyfood from "../assets/img/banner/ohmyfood2.png";
import kanap from "../assets/img/banner/kanap2.png";
import nitro from "../assets/img/banner/nitro.png";
import { useTranslation } from "react-i18next"; 

export default function RevealCards() {
  const { t } = useTranslation();
  const [isInView, setIsInView] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsInView(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView, controls]);

  const titleVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  const borderVariants = {
    hidden: { width: 0 },
    visible: {
      width: "15rem",
      transition: { duration: 0.5 },
    },
  };

  const slideUpVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 0.5 },
    },
  };

  return (
    <section className="px-10 pt-8 pb-20 bg-[#0B0B0F]" id="work">
      <div className="max-w-5xl mx-auto">
        <motion.h1
          className="text-2xl text-white font-bold pb-4 mb-1 w-60 uppercase"
          variants={titleVariants}
          initial="hidden"
          animate={controls}
        >
          {t('work.workTitle')}
        </motion.h1>
        <motion.div
          className="border-b-2 w-60 mb-10"
          variants={borderVariants}
          initial="hidden"
          animate={controls}
        ></motion.div>
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
          variants={slideUpVariants}
          initial="hidden"
          animate={controls}
        >
          <Card
            title={t('work.polarFi.title')}
            description={t('work.polarFi.description')} 
            imgSrc={polarFi}
            id="polarfi"
          />
          <Card
            title={t('work.nitromania.title')} 
            description={t('work.nitromania.description')} 
            imgSrc={nitro}
            id="nitromania"
          />
          <Card
            title={t('work.kasa.title')}
            description={t('work.kasa.description')} 
            imgSrc={kasa}
            id="kasa"
          />
          <Card
            title={t('work.booki.title')}
            description={t('work.booki.description')} 
            imgSrc={booki}
            id="booki"
          />
          <Card
            title={t('work.ohmyfood.title')}
            description={t('work.ohmyfood.description')} 
            imgSrc={ohmyfood}
            id="ohmyfood"
          />
          <Card
            title={t('work.kanap.title')}
            description={t('work.kanap.description')} 
            imgSrc={kanap}
            id="kanap"
          />
        </motion.div>
      </div>
    </section>
  );
}

const Card = ({ title, description, imgSrc, id }) => {
  return (
    <Link to={`/customer/${id}`}>
      {/* Link wraps the card content */}
      <motion.div
        transition={{
          staggerChildren: 0.035,
        }}
        whileHover="hover"
        className="w-full h-[300px] bg-slate-300 overflow-hidden cursor-pointer group relative"
      >
        {/* Background Image */}
        <div
          className="absolute inset-0 saturate-100 md:saturate-0 md:group-hover:saturate-100 group-hover:scale-110 transition-all duration-500"
          style={{
            backgroundImage: `url(${imgSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        {/* Semi-transparent overlay to improve text visibility */}
        <div className="absolute inset-0 bg-[#10141c] bg-opacity-50 group-hover:bg-opacity-40 transition-all duration-500"></div>

        {/* Text Content */}
        <div className="p-4 relative z-20 h-full text-white group-hover:text-white transition-colors duration-500 flex flex-col justify-between">
          {/* Arrow Icon with hover animation */}
          <FiArrowRight className="text-3xl group-hover:-rotate-45 transition-transform duration-500 ml-auto" />

          {/* Title and Description */}
          <div>
            <h2 className="text-2xl font-bold text-white group-hover:text-white transition-colors">
              {title.split("").map((l, i) => (
                <ShiftLetter letter={l} key={i} />
              ))}
            </h2>
            <p className="text-sm font-light text-white group-hover:text-white transition-colors duration-500 mt-2">
              {description}
            </p>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

const ShiftLetter = ({ letter }) => {
  return (
    <div className="inline-block overflow-hidden h-[36px] font-semibold text-3xl">
      <motion.span
        className="flex flex-col min-w-[4px]"
        style={{
          y: "0%",
        }}
        variants={{
          hover: {
            y: "-50%",
          },
        }}
        transition={{
          duration: 0.5,
        }}
      >
        <span>{letter}</span>
        <span>{letter}</span>
      </motion.span>
    </div>
  );
};
