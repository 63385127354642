import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Styles
import "./styles/main.css";

// Pages
import Home from "./pages/Home";
import Customer from "./pages/Customer";
import Error404 from "./pages/Error404";

// Layout
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Loader from "./components/Loader";

function App() {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    // Hide the loader after a delay (e.g., 5 seconds)
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (showLoader) {
    return <Loader />; // This covers the entire viewport
  }

  return (
    <Router>
      {showLoader && <Loader />}
      <div className="relative z-10 bg-[#0B0B0F]">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/customer/:id" element={<Customer />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
