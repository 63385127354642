// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translations for English and French
const resources = {
  en: {
    translation: {
      header: {
        work: "Work",
        experience: "Experience",
        contact: "Contact",
      },
        hero: {
            title: "Hello",
            subtitle: "I'm Logan, Web Developer",
            description: "I work with startups and companies for a few years, I'm passionate about new technologies and their ability to improve business productivity. You are worried about the SEO of your site, don't worry, I take care of that too.",
          },
          work: {
            workTitle: "Work",
              polarFi: {
                title: "PolarFi",
                description: "Currently at the post of CTO at PolarFi, I am in charge of the development of the platform and the management of the technical team."
              },
              nitromania: {
                title: "Nitromania",
                description: "Worked as front-end developer at Nitromania, I was in charge of the development of the website and UI Design."
              },
              kasa: {
                title: "Kasa",
                description: "Worked as Front-End Developer for Kasa, a housing search site."
              },
              booki: {
                title: "Booki",
                description: "Worked as Front-End Developer for Booki, a booking site for hotels."
              },
              ohmyfood: {
                title: "OhMyFood",
                description: "Worked as Front-End Developer for OhMyFood, find your restaurant."
              },
              kanap: {
                title: "Kanap",
                description: "Worked as Front-End Developer for Kanap, an e-commerce site for sofas."
              },
          },
          
        experience: {
            title: "Experience",
            polarFi: {
              title: "PolarFi",
              description: "Chief Technical Officer",
              content: "Currently at the post of CTO at PolarFi, I am in charge of the development of the platform and the management of the technical team.",
              link: "Visit the website"
            },
            kasa: {
              title: "Kasa",
              description: "Front-End Developer",
              content: "Worked as Front-End Developer for Kasa, a housing search site.",
              link: "Visit the website"
            },
            booki: {
              title: "Booki",
              description: "Front-End Developer",
              content: "Worked as Front-End Developer for Booki, a booking site for hotels.",
              link: "Visit the website"
            },
            ohMyFood: {
              title: "OhMyFood",
              description: "Front-End Developer",
              content: "Worked as Front-End Developer for OhMyFood, find your restaurant.",
              link: "Visit the website"
            },
            kanap: {
              title: "Kanap",
              description: "Front-End Developer & Back-End Developer",
              content: "Worked as Front-End Developer for Kanap, an e-commerce site for sofas.",
              link: "Visit the website"
            }
        },
        contact: {
            title:"Contact",
            titleContact: "Connect with me! ✉️",
            email: "contact@loganben.com",
            questions: {
              email: {
                text: "To start, could you give us ",
                postfix: "your email?",
              },
              name: {
                text: "Awesome! And what's ",
                postfix: "your name?",
              },
              description: {
                text: "Perfect, and ",
                postfix: "how can we help you?",
              },
            },
            summary: "Beautiful! Here's what we've got:",
            command: "Enter",
            lookGood: "Look good?",
            sent: "Sent! We'll get back to you ASAP 😎",
            restart: "Restart",
            sendIt: "Send it!",
            invalidEmail: "Please enter a valid email address.",
          },
          customer: {
            tech: "Technology",
            time: "Date",
            site: "Visit website",
            polarfi: {
              name: "PolarFi",
              description: "PolarFi is a web3 platform that allows you to buy and sell Genesis NFTs. There is also a gaming part where you can play with your NFTs and win tokens.",
              date: "January 2022 - Present",
            },
            nitromania: {
              name: "Nitromania",
              description: "Nitromania is a web application that allows you to find the best price for Discord Nitro.",
              date: "September 2024",
            },
            booki: {
              name: "Booki",
              description: "Booki is a web application that allows you to manage your books. You can find a hotel and all activities around you.",
              date: "September 2021",
            },
            ohmyfood: {
              name: "OhMyFood",
              description: "Ohmyfood is a web application that allows you to find the best restaurants in your city. You can also book a table and order your meal in advance.",
              date: "November 2021",
            },
            kanap: {
              name: "Kanap",
              description: "Kanap is an e-commerce website that allows you to buy couches. You can also find a store near you and book a couch.",
              date: "January 2022",
            },
            kasa: {
              name: "Kasa",
              description: "Kasa is a web application that allows you to rent a house or an apartment. You can also rent your own house or apartment.",
              date: "April 2022",
            }
          },
    },
  },
  fr: {
    translation: {
      header: {
        work: "Travaux",
        experience: "Expériences",
        contact: "Contact",
      },
      hero: {
        title: "Bonjour",
        subtitle: "Je suis Logan, Développeur Web",
        description: "Je travaille avec des startups et des entreprises depuis quelques années, je suis passionné par les nouvelles technologies et leur capacité à améliorer la productivité des entreprises. Vous vous inquiétez pour le SEO de votre site, ne vous inquiétez pas, je m'en occupe aussi.",
      },
      work: {
        workTitle: "Travaux",
        polarFi: {
          title: "PolarFi",
          description: "Actuellement en poste en tant que CTO chez PolarFi, je suis en charge du développement de la plateforme et de la gestion de l'équipe technique."
        },
        nitromania: {
          title: "Nitromania",
          description: "J'ai travaillé en tant que développeur front-end chez Nitromania, où j'étais responsable du développement du site Web et de la conception de l'interface utilisateur."
        },
        kasa: {
          title: "Kasa",
          description: "A travaillé en tant que développeur front-end pour Kasa, un site de recherche de logements."
        },
        booki: {
          title: "Booki",
          description: "A travaillé en tant que développeur front-end pour Booki, un site de réservation d'hôtels."
        },
        ohmyfood: {
          title: "OhMyFood",
          description: "A travaillé en tant que développeur front-end pour OhMyFood, trouvez votre restaurant."
        },
        kanap: {
          title: "Kanap",
          description: "A travaillé en tant que développeur front-end pour Kanap, un site e-commerce pour canapés."
        },
      },
      experience: {
        title: "Expérience",
        polarFi: {
          title: "PolarFi",
          description: "Directeur Technique",
          content: "Actuellement en poste en tant que CTO chez PolarFi, je suis en charge du développement de la plateforme et de la gestion de l'équipe technique.",
          link: "Visiter le site"
        },
        kasa: {
          title: "Kasa",
          description: "Développeur Front-End",
          content: "A travaillé en tant que développeur front-end pour Kasa, un site de recherche de logements.",
          link: "Visiter le site"
        },
        booki: {
          title: "Booki",
          description: "Développeur Front-End",
          content: "A travaillé en tant que développeur front-end pour Booki, un site de réservation d'hôtels.",
          link: "Visiter le site"
        },
        ohMyFood: {
          title: "OhMyFood",
          description: "Développeur Front-End",
          content: "A travaillé en tant que développeur front-end pour OhMyFood, trouvez votre restaurant.",
          link: "Visiter le site"
        },
        kanap: {
          title: "Kanap",
          description: "Développeur Front-End & Développeur Back-End",
          content: "A travaillé en tant que développeur front-end pour Kanap, un site e-commerce pour canapés.",
          link: "Visiter le site"
        }
      },
      contact: {
        title:"Contact",
        titleContact: "Connectez-vous avec moi ! ✉️",
        email: "contact@loganben.com",
        questions: {
          email: {
            text: "Pour commencer, pouvez-vous nous donner ",
            postfix: "votre email ?",
          },
          name: {
            text: "Génial ! Et quel est ",
            postfix: "votre nom ?",
          },
          description: {
            text: "Parfait, et ",
            postfix: "comment pouvons-nous vous aider ?",
          },
        },
        summary: "Super ! Voici ce que nous avons :",
        command: "Entrer",
        lookGood: "Ça a l'air bien ?",
        sent: "Envoyé ! Nous vous répondrons dès que possible 😎",
        restart: "Redémarrer",
        sendIt: "Envoyez-le !",
        invalidEmail: "Veuillez entrer une adresse e-mail valide.",
      },
      customer: {
        tech: "Technologies",
        time: "Date",
        site: "Visiter le site",
        polarfi: {
          name: "PolarFi",
          description: "PolarFi est une plateforme web3 qui vous permet d'acheter et de vendre des NFT Genesis. Il y a aussi une partie jeu où vous pouvez jouer avec vos NFTs et gagner des tokens.",
          date: "Janvier 2022 - Présent",
        },
        nitromania: {
          name: "Nitromania",
          description: "Nitromania est une application web qui vous permet de trouver le meilleur prix pour Discord Nitro.",
          date: "Septembre 2024",
        },
        booki: {
          name: "Booki",
          description: "Booki est une application web qui vous permet de gérer vos livres. Vous pouvez trouver un hôtel et toutes les activités autour de vous.",
          date: "Septembre 2021",
        },
        ohmyfood: {
          name: "OhMyFood",
          description: "Ohmyfood est une application web qui vous permet de trouver les meilleurs restaurants de votre ville. Vous pouvez également réserver une table et commander votre repas à l'avance.",
          date: "Novembre 2021",
        },
        kanap: {
          name: "Kanap",
          description: "Kanap est un site e-commerce qui vous permet d'acheter des canapés. Vous pouvez également trouver un magasin près de chez vous et réserver un canapé.",
          date: "Janvier 2022",
        },
        kasa: {
          name: "Kasa",
          description: "Kasa est une application web qui vous permet de louer une maison ou un appartement. Vous pouvez également louer votre propre maison ou appartement.",
          date: "Avril 2022",
        }
      },
    }
  }  
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'fr', 
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
