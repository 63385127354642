import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";
import logo from "../assets/img/logo.png";
import frenchFlag from "../assets/img/french-flag.png";
import englishFlag from "../assets/img/english-flag.png";
import { useTranslation } from "react-i18next"; 

export default function Nav() {
  const { t, i18n } = useTranslation(); 

  const toggleLanguage = () => {
    i18n.changeLanguage(i18n.language === "fr" ? "en" : "fr");
  };

  return (
    <nav className="z-50 fixed left-[50%] top-8 flex w-[350px] md:w-fit -translate-x-[50%] items-center gap-2 md:gap-4 rounded-lg border-[1px] border-neutral-700 bg-neutral-900 p-3 md:text-sm text-neutral-500">
      <Link to="/">
        <img className="h-9 w-9" src={logo} alt="logo" />
      </Link>
      <NavLink to="#work">{t('header.work')}</NavLink>
      <NavLink to="#experience">{t('header.experience')}</NavLink>
      <JoinButton />
      <img
        src={i18n.language === "fr" ? englishFlag : frenchFlag} 
        alt={i18n.language === "fr" ? "English Flag" : "French Flag"}
        className="h-5 w-6 ml-2 cursor-pointer"
        onClick={toggleLanguage}
      />
    </nav>
  );
}

const NavLink = ({ to, children }) => {
  return (
    <Link
      to={"/" + to}
      className="block overflow-hidden"
      scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
    >
      <motion.div
        whileHover={{ y: -20 }}
        transition={{ ease: "backInOut", duration: 0.5 }}
        className="h-[20px]"
      >
        <span className="flex h-[20px] items-center">{children}</span>
        <span className="flex h-[20px] items-center text-neutral-50">
          {children}
        </span>
      </motion.div>
    </Link>
  );
};

const JoinButton = () => {
  const { t } = useTranslation();
  return (
    <Link
      to="/#contact"
      className={`relative z-0 flex items-center gap-2 overflow-hidden 
                  whitespace-nowrap rounded-lg border-[1px] border-neutral-700 
                  px-4 py-1.5 font-medium text-neutral-300 transition-all 
                  duration-300 before:absolute before:inset-0 before:-z-10 
                  before:translate-y-[200%] before:scale-[2.5] before:rounded-[100%] 
                  before:bg-neutral-50 before:transition-transform 
                  before:duration-1000 before:content-[""] hover:scale-105 
                  hover:border-neutral-50 hover:text-neutral-900 
                  hover:before:translate-y-[0%] active:scale-100`}
      scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
    >
      {t('header.contact')}
    </Link>
  );
}
